import BurstPuckerImageTitles from "@/components/common/burstPuckerImageTitles";
import Link from "next/link";

import FIAP from "@/assets/images/home/awardsPhoto/FIAP-seed-awards-photo.webp";
import AWARDS from "@/assets/images/home/awardsPhoto/premier-contest-celebrating-seed-awards-photo.webp";
import EURASIA from "@/assets/images/home/awardsPhoto/EURASIA-seed-awards-photo.webp";
import Image from "next/image";
import AwardsPhotosHero from "./components/AwardsPhotosHero";
import ImageHighwaySwiper from "./components/ImageHighwaySwiper";
import AwardsGrantedLogos from "./components/AwardsGrantedLogos";
import AwardsGallery from "./components/AwardsGallery";
import AwardsCards from "./components/AwardsCards";

function AwardsPhotoSections() {
  return (
    <>
      <div className="center">
        <div className="home__nftMerketplace_head">
          <div className="home__nftMerketplace__title burst home__awardsPtotos__color">
            <BurstPuckerImageTitles /> AWARDS.PHOTOS
          </div>

          <div className="home__nftMerketplace__btnExploreforPC">
            <Link
              className="button-stroke home__awardsPtotos__color"
              href="https://awards.photos/"
              target="_blank"
            >
              Explore More
              <i className="icon-chevron_up"></i>
            </Link>
          </div>
        </div>

        <AwardsPhotosHero />

        <AwardsCards />
      </div>

      <div className="highway">
        <ImageHighwaySwiper />
      </div>

      <div className="center">
        <AwardsGrantedLogos />

        <AwardsGallery />
      </div>

      <div className="home__nftMerketplace">
        <Link
          className="button-stroke home__twitterCommunity__tablet home__awardsPtotos__color"
          href="https://awards.photos/"
          target="_blank"
        >
          Explore More
          <i className="icon-chevron_up"></i>
        </Link>
      </div>
    </>
  );
}

export default AwardsPhotoSections;
