import Image from "next/image";
import Link from "next/link";
import React from "react";
import FIAP from "@/assets/images/home/awardsPhoto/FIAP-seed-awards-photo.webp";
import AWARDS from "@/assets/images/home/awardsPhoto/premier-contest-celebrating-seed-awards-photo.webp";
import EURASIA from "@/assets/images/home/awardsPhoto/EURASIA-seed-awards-photo.webp";

function AwardsCards() {
  return (
    <div className="home__awardsPtotos_flex">
      <Link
        href="https://awards.photos/"
        target="_blank"
        className="home__awardsPtotos__section"
      >
        <div className="inner">
          <div className="image_holder">
            <Image
              width={232}
              height={236}
              src={FIAP}
              alt="FIAP-seed-awards-photo"
              className="home__awardsPtotos__img"
            />
          </div>

          <div className="home__awardsPtotos__title home__awardsPtotos__color">
            FIAP
          </div>
          <p className="home__awardsPtotos__text">
            The largest{" "}
            <span className="text_colorful">international organization</span>{" "}
            dedicated to promoting art photography, offering prestigious
            recognition, awards, and opportunities for photographers.
          </p>
        </div>
      </Link>
      <Link
        href="https://awards.photos/"
        target="_blank"
        className="home__awardsPtotos__section"
      >
        <div className="inner">
          <div className="image_holder">
            <Image
              width={190}
              height={195}
              src={AWARDS}
              alt="premier-contest-celebrating-seed-awards-photo"
              className="home__awardsPtotos__img"
            />
          </div>
          <div className="home__awardsPtotos__title home__awardsPtotos__color">
            AWARDS
          </div>
          <p className="home__awardsPtotos__text">
            A <span className="text_colorful">premier contest celebrating</span>{" "}
            Collaborating with Eurasia, FIAP, and SEED.Photo, it offers a global
            stage and prizes
          </p>
        </div>
      </Link>
      <Link
        href="https://awards.photos/"
        target="_blank"
        className="home__awardsPtotos__section"
      >
        <div className="inner">
          <div className="image_holder">
            <Image
              width={218}
              height={249}
              src={EURASIA}
              alt="EURASIA-seed-awards-photo"
              className="home__awardsPtotos__img"
            />
          </div>
          <div className="home__awardsPtotos__title home__awardsPtotos__color">
            EURASIA
          </div>
          <p className="home__awardsPtotos__text">
            A vibrant
            <span className="text_colorful">&nbsp;global community</span>{" "}
            fostering art photography, diversity, and creativity. Join us to be
            part of the largest and most welcoming community!
          </p>
        </div>
      </Link>
    </div>
  );
}

export default AwardsCards;
