import MinimalCardInnerDetails from "@/components/common/cards/minimalCard/minimalCardInnerDetails";
import { EnumRatioType } from "@/components/constants/layout";
import { selectNewListingData } from "@/config/helpers/createReduxSelectors";
import { NewlistingData } from "@/store/actions";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const dataAwardImages = [
  {
    Nft: {
      Id: 1,
      AuctionExpireDate: null,
      // "ChainId": 97,
      // "Slug": "mrbean-museum",
      // "ImageUrl": "caa65de551a14e9681ce70184826b48f.jpg",
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl:
        "image_fe12b87a6360230fa41ea0be51f799af-ezgif.com-jpg-to-webp-converter.webp",
    },
  },
  {
    Nft: {
      Id: 2,
      RatioType: EnumRatioType.Portrait,
      StartSellFrom: null,
      ImageUrl:
        "image_eb36d6411a78d614aefea1d5010d72d7-ezgif.com-jpg-to-webp-converter.webp",
    },
  },
  {
    Nft: {
      Id: 3,
      RatioType: EnumRatioType.Portrait,
      StartSellFrom: null,
      ImageUrl: "ezgif.com-jpg-to-webp-converter.webp",
    },
  },
  {
    Nft: {
      Id: 4,
      RatioType: EnumRatioType.Portrait,
      StartSellFrom: null,
      ImageUrl: "image_d38f7ad5475dbf798fe1f63db00c98bb.webp",
    },
  },
  {
    Nft: {
      Id: 5,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_cf749df27817dad6cf76fa8166204b42.webp",
    },
  },
  {
    Nft: {
      Id: 6,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_a2d6e579c9d0eadf2d45a1d0445e8cff.webp",
    },
  },
  {
    Nft: {
      Id: 7,
      RatioType: EnumRatioType.Portrait,
      StartSellFrom: null,
      ImageUrl: "image_9487939109862d4003787247d37efc15.webp",
    },
  },
  {
    Nft: {
      Id: 8,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_492304355cf0ebbb4311b7be2550a268.webp",
    },
  },
  {
    Nft: {
      Id: 9,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_94389c704365bb9b9017a6fc64b4f179.webp",
    },
  },
  {
    Nft: {
      Id: 10,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_42083ddf97639af88dcb7c4976a72132.webp",
    },
  },
  {
    Nft: {
      Id: 11,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_633c895d19376ee29a436d20b50c959f.webp",
    },
  },
  {
    Nft: {
      Id: 12,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_91a0a626222856302e1ad8a4ef996782.webp",
    },
  },
  {
    Nft: {
      Id: 13,
      RatioType: EnumRatioType.Landscape,
      StartSellFrom: null,
      ImageUrl: "image_49a7ab0d8ff8ff20ed3d9280445cbdff.webp",
    },
  },
  {
    Nft: {
      Id: 14,
      RatioType: EnumRatioType.Portrait,
      StartSellFrom: null,
      ImageUrl: "image_8c49696e46d782de7f6b2b21dc86bf38.webp",
    },
  },
  {
    Nft: {
      Id: 15,
      RatioType: EnumRatioType.Portrait,
      StartSellFrom: null,
      ImageUrl: "image_1b26dd6dea85dd9db1860dee0a5819ef.webp",
    },
  },
];

function ImageHighwaySwiper({ showProfile }) {
  const dispatch = useDispatch();
  const [nftData, setNftData] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const swipertop = useRef();
  const swiperbot = useRef();

  const { successNewListingNfts, nfts } = useSelector(selectNewListingData);

  useEffect(() => {
    if (isEmpty(nfts)) {
      setTimeout(() => {
        dispatch(NewlistingData());
      }, 2500);
    }

    if (!isEmpty(nfts)) {
      setNftData(nfts);
    }
  }, [dispatch, nfts, nftData]);

  useEffect(() => {
    if (swiper && nftData) {
      swiper.slideTo(nftData.length - 1, 0, false);
    }
  }, [swiper, nftData]);

  // const handleMouseEnter = () => {
  //   swipertop?.current?.swiper?.autoplay?.stop();
  //   swiperbot?.current?.swiper?.autoplay?.stop();
  // };

  // const handleMouseLeave = () => {
  //   swipertop?.current?.swiper?.autoplay?.start();
  //   swiperbot?.current?.swiper?.autoplay?.start();
  // };

  return (
    <div className="noback">
      <div className="hot__center">
        <div className="hot__wrapper">
          <div className="container-slider-all">
            <div className="container-slider">
              <div className="slider1">
                {dataAwardImages &&
                  dataAwardImages.slice(0, 8).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
                {dataAwardImages &&
                  dataAwardImages.slice(0, 8).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
                {dataAwardImages &&
                  dataAwardImages.slice(0, 8).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
                {dataAwardImages &&
                  dataAwardImages.slice(0, 8).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
              </div>
            </div>

            <div className="container-slider">
              <div className="slider2">
                {dataAwardImages &&
                  dataAwardImages.slice(8, 15).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
                {dataAwardImages &&
                  dataAwardImages.slice(8, 15).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
                {dataAwardImages &&
                  dataAwardImages.slice(8, 15).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
                {dataAwardImages &&
                  dataAwardImages.slice(8, 15).map((item, index) => (
                    // <div key={item.Nft.Id} className="home-card-width">
                    <MinimalCardInnerDetails
                      item={item}
                      component={"highwaySlider__car"}
                      key={item.Nft.Id}
                      isSlide={true}
                      showProfile={false}
                      classNamecard={"home-card-width"}
                    />
                    // </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ImageHighwaySwiper);
