import React from "react";
import { Clash } from "next/font/google";

function AwardsPhotosHero() {
  return (
    <>
      <div className="hero_awards"></div>
      <div className="awards_text">AWARDS</div>
      <div className="photos_text text_colorful">PHOTOS</div>
      <p className="awards_description">
        A premier photography contest recognizing and rewarding excellence. Open
        to both amateur and professional photographers, the platform, in
        collaboration with Eurasia, FIAP, and SEED.Photo provides a global
        community to showcase your talent and compete for cash prizes and
        recognition. Join us today and celebrate the art of photography.
      </p>
      <div className="awards_summary">
        <div className="first home__awardsPtotos__color">
          Creative Eurasia <b>NFT</b> Photo Salon 2023
        </div>
        <div className="home__awardsPtotos__color">
          <b>FIAP</b> Patronage # 2023/428
        </div>
      </div>
    </>
  );
}

export default AwardsPhotosHero;
