import React from "react";
import awards1 from "@/public/images/awards-photo/gallery/awards-1.webp";
import awards2 from "@/public/images/awards-photo/gallery/awards-2.webp";
import awards3 from "@/public/images/awards-photo/gallery/awards-3.webp";
import awards4 from "@/public/images/awards-photo/gallery/awards-4.webp";
import awards5 from "@/public/images/awards-photo/gallery/awards-5.webp";
import awards6 from "@/public/images/awards-photo/gallery/awards-6.webp";
import awards7 from "@/public/images/awards-photo/gallery/awards-7.webp";
import awards8 from "@/public/images/awards-photo/gallery/awards-8.webp";
import awards9 from "@/public/images/awards-photo/gallery/awards-8.webp";
import awards10 from "@/public/images/awards-photo/gallery/awards-10.webp";
import awards11 from "@/public/images/awards-photo/gallery/awards-11.webp";
import awards12 from "@/public/images/awards-photo/gallery/awards-12.webp";
import awards13 from "@/public/images/awards-photo/gallery/awards-13.webp";
import awards14 from "@/public/images/awards-photo/gallery/awards-14.webp";
import Image from "next/image";

function AwardsGallery() {
  return (
    <>
      <div className="awards_gallery">
        <div className="gallery_item">
          <Image src={awards1} alt="awards1-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards2} alt="awards2-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards3} alt="awards3-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards4} alt="awards4-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards5} alt="awards5-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards6} alt="awards6-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards7} alt="awards7-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards8} alt="awards8-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image src={awards9} alt="awards9-gallery" width={173} height={115} />
        </div>

        <div className="gallery_item">
          <Image
            src={awards10}
            alt="awards10-gallery"
            width={173}
            height={115}
          />
        </div>

        <div className="gallery_item">
          <Image
            src={awards11}
            alt="awards11-gallery"
            width={173}
            height={115}
          />
        </div>

        <div className="gallery_item">
          <Image
            src={awards12}
            alt="awards12-gallery"
            width={173}
            height={115}
          />
        </div>

        <div className="gallery_item">
          <Image
            src={awards13}
            alt="awards13-gallery"
            width={173}
            height={115}
          />
        </div>

        <div className="gallery_item">
          <Image
            src={awards14}
            alt="awards14-gallery"
            width={173}
            height={115}
          />
        </div>
      </div>
    </>
  );
}

export default AwardsGallery;
